import {
	GET_NURSE_LICENSES_REQUEST,
	GET_NURSE_LICENSES_SUCCESS,
	GET_NURSE_LICENSES_ERROR,
	GET_NURSE_LICENSE_REQUEST,
	GET_NURSE_LICENSE_SUCCESS,
	GET_NURSE_LICENSE_ERROR,
	CREATE_NURSE_LICENSE_REQUEST,
	CREATE_NURSE_LICENSE_SUCCESS,
	CREATE_NURSE_LICENSE_ERROR,
	EDIT_NURSE_LICENSE_REQUEST,
	EDIT_NURSE_LICENSE_SUCCESS,
	EDIT_NURSE_LICENSE_ERROR,
	DELETE_NURSE_LICENSE_REQUEST,
	DELETE_NURSE_LICENSE_SUCCESS,
	DELETE_NURSE_LICENSE_ERROR
} from "@/actions"

import {
	nurseLicenseService
} from "@/services"

const state = {
	nurseLicenses: [],
	nurseLicense: {},
	loading: false,
	loadedOnce: false
}

import { addNewFilter } from "@/utilities";

const getters = {
	nlLoading: state => state.loading,
	nlLoadedOnce: state => state.loadedOnce,
	nurseLicenses: state => state.nurseLicenses.sort((a, b) => a?.label.localeCompare(b?.label)),
	nurseLicenseSelectOptions: (state, getters) => getters.nurseLicenses.map(nl => ({ value: nl.uuid, label: nl.label })),
	nurseLicenseMultiSelectOptions: state => state.nurseLicenses.map(nl => ({ value: nl.uuid, text: nl.label })),
	nurseLicenseByUuid: state => nlUuid => state.nurseLicenses.find(nl => nl.uuid == nlUuid)
}

const actions = {
	async getNurseLicenses({ commit }) {
		commit("GET_NURSE_LICENSES_REQUEST")
		const response = await nurseLicenseService.getNurseLicenses()
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_NURSE_LICENSES_SUCCESS", data)
				break
			default:
				commit("GET_NURSE_LICENSES_ERROR", data)
		}
		return response
	},
	async getNurseLicense({ commit }, params) {
		commit("GET_NURSE_LICENSE_REQUEST")
		const response = await nurseLicenseService.getNurseLicense(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_NURSE_LICENSE_SUCCESS", data)
				break
			default:
				commit("GET_NURSE_LICENSE_ERROR", data)
				break
		}
		return response
	},
	async createNurseLicense({ commit }, params) {
		commit("CREATE_NURSE_LICENSE_REQUEST")
		const response = await nurseLicenseService.createNurseLicense(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_NURSE_LICENSE_SUCCESS", data)
				break
			default:
				commit("CREATE_NURSE_LICENSE_ERROR", data)
		}
		return response
	},
	async editNurseLicense({ commit }, params) {
		commit("EDIT_NURSE_LICENSE_REQUEST")
		const uuid = params.uuid
		const response = await nurseLicenseService.editNurseLicense(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_NURSE_LICENSE_SUCCESS", data)
				break
			default:
				commit("EDIT_NURSE_LICENSE_ERROR", data)
		}
		return response
	},
	async deleteNurseLicense({ commit }, params) {
		commit("DELETE_NURSE_LICENSE_REQUEST")
		const response = await nurseLicenseService.deleteNurseLicense(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_NURSE_LICENSE_SUCCESS", data)
				break
			default:
				commit("DELETE_NURSE_LICENSE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_NURSE_LICENSES_REQUEST]: state => {
		state.loading = true
	},
	[GET_NURSE_LICENSES_SUCCESS]: (state, data) => {
		const nurse_licenses = data.nurse_licenses
		state.nurseLicenses = addNewFilter(state.nurseLicenses, nurse_licenses)
		state.loading = false
	},
	[GET_NURSE_LICENSES_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_NURSE_LICENSE_REQUEST]: state => {
		state.loading = true
	},
	[GET_NURSE_LICENSE_SUCCESS]: (state, data) => {
		const { nurseLicense } = data
		state.nurseLicense = nurseLicense
		state.nurseLicenses = addNewFilter(state.nurseLicenses, [nurseLicense])
		state.loading = false
	},
	[GET_NURSE_LICENSE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_NURSE_LICENSE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_NURSE_LICENSE_SUCCESS]: (state, data) => {
		const { nurse_license } = data
		state.nurseLicenses = addNewFilter(state.nurseLicenses, [nurse_license])
		state.loading = false
	},
	[CREATE_NURSE_LICENSE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_NURSE_LICENSE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_NURSE_LICENSE_SUCCESS]: state => {
		const { nurse_license } = data
		state.nurseLicenses = state.nurseLicenses.map(nl => nl.uuid == nurse_license.uuid ? nurse_license : nl)
		state.loading = false
	},
	[EDIT_NURSE_LICENSE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_NURSE_LICENSE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_NURSE_LICENSE_SUCCESS]: (state, data) => {
		const { nurseLicense } = data
		state.nurseLicenses = state.nurseLicenses.filter(nl => nl.uuid !== nurseLicense.uuid)
		state.loading = false
	},
	[DELETE_NURSE_LICENSE_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const nurseLicense = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
